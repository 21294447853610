import MyTable from "./inc/MyTable";

const Home = () => {
  return (
    <>
      <MyTable />
    </>
  );
};

export default Home;
