import axios from "axios";
let apiClient = axios.create();
export const initializeApiClient = () => {
  const API_KEY = process.env.REACT_APP_API_KEY;
  const BACKEND_API_PREFIX = process.env.REACT_APP_API_PREFIX;
  async function generateHMACSignature(secret, data) {
    if (!secret) {
      console.error("Secret key is missing!");
      return "";
    }
    const encoder = new TextEncoder();
    const key = await crypto.subtle.importKey(
      "raw",
      encoder.encode(secret),
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign"]
    );
    const signatureBuffer = await crypto.subtle.sign(
      "HMAC",
      key,
      encoder.encode(data)
    );
    return Array.from(new Uint8Array(signatureBuffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  }
  apiClient = axios.create();
  // Add request interceptor
  apiClient.interceptors.request.use(async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const timestamp = Date.now();
    const endpoint = "/api/auth/location/search";
    const signature = await generateHMACSignature(
      API_KEY,
      `${endpoint}${timestamp}`
    );
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (API_KEY) {
      config.headers["X-Timestamp"] = timestamp.toString();
      config.headers["X-Signature"] = signature;
      config.headers["X-Source"] = "web";
    }
    return config;
  });
  // Add response interceptor for handling expired tokens
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // Handle 401 Unauthorized errors
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          try {
            // Call refresh token endpoint
            const refreshResponse = await axios.post(
              `${BACKEND_API_PREFIX}${process.env.REACT_APP_AUTH_REFRESH_TOKEN}`,
              {
                refreshToken,
              }
            );
            const newAccessToken = refreshResponse.data.accessToken;
            if (newAccessToken) {
              // Save the new access token
              localStorage.setItem("accessToken", newAccessToken);
              // Update the Authorization header in apiClient
              apiClient.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              // Retry the failed request with the new token
              return apiClient.request(originalRequest);
            }
          } catch (refreshError) {
            console.error("Failed to refresh token: ", refreshError);
            // Redirect to login if refresh token fails
            handleLogout();
          }
        } else {
          // No refresh token available, log out
          handleLogout();
        }
      }
      return Promise.reject(error);
    }
  );
};
// Handle logout logic
const handleLogout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.href = "/login"; // Redirect to login page
};
export const reinitializeApiClient = () => {
  initializeApiClient();
};
initializeApiClient();
export default apiClient;
