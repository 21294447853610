import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import i18next from "i18next";

export default function CertifiedMail() {
  const sampleData = [
    { id: 1, phoneNumber: "03-000000" },
    { id: 2, phoneNumber: "03-000000" },
    { id: 3, phoneNumber: "03-000000" },
  ];

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleCheckboxChange = (id: number) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedRows((prevSelectedRows) =>
      selectAll ? [] : sampleData.map((row: any) => row.id)
    );
  };

  const isSelected = (rowId: number) => selectedRows.indexOf(rowId) !== -1;

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <h2 style={{ marginBottom: "6px" }}>Certified mail</h2>

      <TableContainer component={Paper} sx={{ border: "2px solid #f2f3f5" }}>
        <Table
          aria-label="simple table"
          dir={`${i18next.language === "en" ? "ltr" : "rtl"}`}
        >
          <TableHead sx={{ backgroundColor: "#f2f3f5" }}>
            <TableRow>
              <TableCell>Postal approved phone numbers</TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < sampleData.length
                      }
                      checked={selectedRows.length === sampleData.length}
                      onChange={handleSelectAll}
                      sx={{
                        "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                          color: "#fe0000 !important",
                        },
                      }}
                    />
                  }
                  label="Select All"
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sampleData.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                selected={isSelected(row.id)}
              >
                <TableCell component="th" scope="row">
                  {row.phoneNumber}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={isSelected(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#fe0000 !important",
                      },
                    }}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
