import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils/Helper";
import useApi from "../hooks/useApi";
import promotionApi from "../api/promotion";
import { Backdrop, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "../context/snackbarContext";
const { format } = require("date-fns");

const Complaints = () => {
  const { t } = useTranslation();

  const [complaintsList, setComplaintsList] = useState<any[]>([]);

  const { showSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    {
      field: "businessName",
      headerName: capitalizeFirstLetter(t("lbl.business_name")),
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/business-profile/${row.businessId}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            {row.businessName}
          </Link>
        );
      },
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "promotionId",
    //   headerName: "Promotion Id",
    //   width: 150,
    // },
    {
      field: "issueType",
      headerName: capitalizeFirstLetter("Issue Type"),
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isActive",
      headerName: capitalizeFirstLetter(t("lbl.account_status")),
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.value === true ? "Active" : "Inactive";
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const formattedDate = format(new Date(params.value), "dd/MMM/yyyy");
        return formattedDate;
      },
      resizable: true,
      width: 150,
    },
  ];

  const getComplaintsApi = useApi(promotionApi.getAllComplaints);

  useEffect(() => {
    getComplaintsApi.request("");
  }, []);

  useEffect(() => {
    let complaintApiResponse: any = getComplaintsApi?.data;
    if (complaintApiResponse) {
      if (
        complaintApiResponse?.data &&
        complaintApiResponse?.data?.length > 0
      ) {
        setComplaintsList(complaintApiResponse?.data);
      } else {
        showSnackbar(complaintApiResponse?.message || "No data found");
      }
    }
  }, [getComplaintsApi?.data]);

  return (
    <>
      <h2>Complaints</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <DataGrid
          rows={complaintsList}
          loading={getComplaintsApi.loading}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          getRowId={(row) => row.complaintId}
          sx={{
            width: {
              xs: "200px", // width for small screens
              sm: "400px", // width for small-medium screens
              md: "700px", // width for medium screens
              lg: "1000px", // width for large screens
              xl: "1300px", // width for extra-large screens
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                margin: "0",
              },
            "--DataGrid-overlayHeight": "520px",
            "& .Mui-checked, & .MuiCheckbox-indeterminate": {
              color: "#fe0000 !important",
            },
          }}
          disableColumnMenu
          autoHeight
        />
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Complaints;
